<template>
  <div class="h-100">
    <apexchart type="line" :options="options" height="350" :series="series" />
  </div>
</template>
<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "BubbleChartV2",
  props: {
    chartSeries: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const options = ref({
      chart: {
        height: 350,
        type: "line",
        zoom: {
          type: "xy",
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bubble: {
          zScaling: true,
          minBubbleRadius: 7,
          maxBubbleRadius: 100,
        },
      },
      xaxis: {
        type: "numeric",
        tooltip: {
          enabled: false,
        },
        tickAmount: 12,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "rgb(111, 115, 144)",
        },
        title: {
          text: "listeners",
          style: {
            color: labelColor,
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      yaxis: {
        title: {
          text: "streams",
          style: {
            color: labelColor,
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
        style: {
          fontSize: "12px",
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const img = data.imageUrl;
          return (
            "<div style='min-width: 250px; max-width: 350px;'>" +
            "<ul class='m-6 ms-0 tooltip-list'>" +
            "<div class='d-flex align-items-center mb-4'><img src='" +
            img +
            "' alt='Avatar' class='avatar-tooltip'/><span class='ms-2 truncate'><b>" +
            data.name +
            "</b> </span></div>" +
            "<li>" +
            "<div class='row'>" +
            "<div class='col-6'>Listeners :</div>" +
            "<div class='col-6'><b>" +
            formatNumberIntoHumanizeForm(data.x) +
            "</b></div>" +
            "</div>" +
            "</li>" +
            "<li>" +
            "<div class='row'>" +
            "<div class='col-6'>Streams :</div>" +
            "<div class='col-6'><b class='truncate'>" +
            formatNumberIntoHumanizeForm(data.y) +
            "</b></div>" +
            "</div>" +
            "</li>" +
            "<div class='row'>" +
            "<div class='col-6'>Playlist Reach :</div>" +
            "<div class='col-6'><b class='truncate'>" +
            formatNumberIntoHumanizeForm(data.z) +
            "</b></div>" +
            "</div>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
      },
    });
    const series = ref(props.chartSeries);
    return { options, series };
  },
};
</script>
<style>
.avatar-tooltip {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tooltip-list {
  list-style-type: none;
}
</style>
