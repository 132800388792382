<template>
  <div class="mt-2 genre-overview-v2">
    <el-row :gutter="12">
      <el-col :lg="8" :md="8" :sm="24" class="margin-bottom-12">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <KpiCardV2
          :title="
            computedValues['medianOfchartmetricSpotifyListenersGrowth12Months']
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricSpotifyListenersGrowth12Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'12 Months'"
          :subText="'Median Listener Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#ff6699'"
              :show-y-axis="false"
              :show-x-axis="true"
              :bar-height="'300'"
              :bar-width="'60%'"
              :graph-series="
                checkIfArrayExists(spotifyListenersGrowth, '12Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </KpiCardV2>
      </el-col>
      <el-col :lg="8" :md="8" :sm="24" class="margin-bottom-12">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <KpiCardV2
          :title="
            computedValues['medianOfchartmetricSpotifyListenersGrowth24Months']
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricSpotifyListenersGrowth24Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'24 Months'"
          :subText="'Median Listener Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#91D6FC'"
              :show-y-axis="false"
              :show-x-axis="true"
              :bar-height="'300'"
              :bar-width="'60%'"
              :graph-series="
                checkIfArrayExists(spotifyListenersGrowth, '24Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </KpiCardV2>
      </el-col>
      <el-col :lg="8" :md="8" :sm="24" class="margin-bottom-12">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <KpiCardV2
          :title="
            computedValues['medianOfchartmetricSpotifyListenersGrowth36Months']
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricSpotifyListenersGrowth36Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'36 Months'"
          :subText="'Median Listener Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#00e673'"
              :show-y-axis="false"
              :show-x-axis="true"
              :bar-height="'300'"
              :bar-width="'60%'"
              :graph-series="
                checkIfArrayExists(spotifyListenersGrowth, '36Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </KpiCardV2>
      </el-col>
    </el-row>
    <el-row :gutter="12">
      <el-col :lg="6" :md="6" :sm="12" class="margin-bottom-12">
        <kpi-card-v2
          :title="
            computedValues['totalArtists']
              ? formatNumberIntoHumanizeForm(computedValues['totalArtists'])
              : 0
          "
          :text="'Total Artists'"
        />
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="margin-bottom-12">
        <kpi-card-v2
          :title="
            computedValues['totalPlaylists']
              ? formatNumberIntoHumanizeForm(computedValues['totalPlaylists'])
              : 0
          "
          :text="'Total Playlists'"
        />
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="margin-bottom-12">
        <kpi-card-v2
          :title="
            computedValues['totalPlaylistsReach']
              ? formatNumberIntoHumanizeForm(
                  computedValues['totalPlaylistsReach']
                )
              : 0
          "
          :text="'Total Playlist Reach'"
        />
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="margin-bottom-12">
        <kpi-card-v2
          :title="
            computedValues['medianPlaylistsReach']
              ? formatNumberIntoHumanizeForm(
                  computedValues['medianPlaylistsReach']
                )
              : 0
          "
          :text="'Reach per Artist'"
        />
      </el-col>
    </el-row>
    <el-row :gutter="12">
      <el-col :lg="6" :md="6" :sm="12" class="mb-5 mb-xl-10">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <kpi-card-v2
          :title="
            computedValues['medianOfchartmetricSpotifyFollowersGrowth12Months']
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricSpotifyFollowersGrowth12Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'12 Months'"
          :sub-text="'Median Follower Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#40bf80'"
              :show-y-axis="false"
              :show-x-axis="false"
              :bar-height="'150'"
              :bar-width="'55%'"
              :graph-series="
                checkIfArrayExists(spotifyFollowersGrowth, '12Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </kpi-card-v2>
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="mb-5 mb-xl-10">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <kpi-card-v2
          :title="
            computedValues['medianOfchartmetricSpotifyFollowersGrowth24Months']
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricSpotifyFollowersGrowth24Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'24 Months'"
          :sub-text="'Median Follower Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#40bf80'"
              :show-y-axis="false"
              :show-x-axis="false"
              :bar-height="'150'"
              :bar-width="'55%'"
              :graph-series="
                checkIfArrayExists(spotifyFollowersGrowth, '24Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </kpi-card-v2>
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="mb-5 mb-xl-10">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <kpi-card-v2
          :title="
            computedValues[
              'medianOfchartmetricInstagramFollowersGrowth12Months'
            ]
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricInstagramFollowersGrowth12Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'12 Months'"
          :sub-text="'Median Instagram Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#6600cc'"
              :show-y-axis="false"
              :show-x-axis="false"
              :bar-height="'150'"
              :bar-width="'55%'"
              :graph-series="
                checkIfArrayExists(instagramFollowersGrowth, '12Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </kpi-card-v2>
      </el-col>
      <el-col :lg="6" :md="6" :sm="12" class="mb-5 mb-xl-10">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <kpi-card-v2
          :title="
            computedValues[
              'medianOfchartmetricInstagramFollowersGrowth24Months'
            ]
              ? formatNumberIntoHumanizeForm(
                  computedValues[
                    'medianOfchartmetricInstagramFollowersGrowth24Months'
                  ]
                ) + '%'
              : '0%'
          "
          :text="'24 Months'"
          :sub-text="'Median Instagram Growth'"
          v-if="!loading"
        >
          <template v-slot:body>
            <RoundedBarGraph
              :bar-color="'#6600cc'"
              :show-y-axis="false"
              :show-x-axis="false"
              :bar-height="'150'"
              :bar-width="'55%'"
              :graph-series="
                checkIfArrayExists(instagramFollowersGrowth, '24Months')
              "
              :series-name="'Number of Artists'"
            />
          </template>
        </kpi-card-v2>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import KpiCardV2 from "@/common/components/KpiCardV2";
import RoundedBarGraph from "@/genre/RoundedBarGraph";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import { getGenreListenersGrowthV2 } from "@/api/genreResult";

export default {
  name: "GenreOverviewV2",
  components: {
    KpiCardV2,
    RoundedBarGraph,
    EmptyCardLoading,
  },
  props: {
    computedValues: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const spotifyListenersGrowth = ref({
      "12Months": [],
      "24Months": [],
      "36Months": [],
    });
    const spotifyFollowersGrowth = ref({
      "12Months": [],
      "24Months": [],
      "36Months": [],
    });
    const instagramFollowersGrowth = ref({
      "12Months": [],
      "24Months": [],
      "36Months": [],
    });
    onMounted(async () => {
      try {
        loading.value = true;
        let url = "";
        if (route.query.type === "main") {
          url = "/mongo-chartmetric-genres";
        } else {
          url = "/mongo-genre";
        }
        let { data } = await getGenreListenersGrowthV2(url, route.query.genre);
        spotifyListenersGrowth.value = data.chartmetricSpotifyListenersGrowth;
        spotifyFollowersGrowth.value = data.chartmetricSpotifyFollowersGrowth;
        instagramFollowersGrowth.value =
          data.chartmetricInstagramFollowersGrowth;
        data = {};
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });
    const checkIfArrayExists = (obj, key) => {
      if (obj && obj[key] && obj[key].length > 0) {
        return obj[key];
      } else {
        return [];
      }
    };
    return {
      loading,
      spotifyListenersGrowth,
      spotifyFollowersGrowth,
      instagramFollowersGrowth,
      formatNumberIntoHumanizeForm,
      checkIfArrayExists,
    };
  },
};
</script>
