<template>
  <div class="social-media-v2 mt-2">
    <EmptyCardLoading :loading="loading" v-if="loading" class="mb-5 mb-xl-10" />
    <div class="card mb-5 mb-xl-10" v-if="!loading && series.length > 0">
      <div class="card-body">
        <BubbleChartV2 :chart-series="series" />
      </div>
    </div>
  </div>
</template>
<script>
import BubbleChartV2 from "@/common/components/BubbleChartV2";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getGenreStreamsV2 } from "@/api/genreResult";
export default {
  name: "GenreSocialMediaV2",
  components: { BubbleChartV2, EmptyCardLoading },
  setup() {
    const loading = ref(false);
    const series = ref([]);
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        let url = "";
        if (route.query.type === "main") {
          url = "/mongo-chartmetric-genres";
        } else {
          url = "/mongo-genre";
        }
        let { data } = await getGenreStreamsV2(url, route.query.genre);
        series.value.push(
          {
            name: "Streams",
            type: "bubble",
            data: data.combinedListenersStreamsAndPlaylistReachValues,
          },
          {
            name: "Trend",
            type: "line",
            data: data.valuesForLeastSquareLine,
          }
        );
        data = {};
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });
    return { loading, series };
  },
};
</script>
