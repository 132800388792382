<template>
  <div class="genre-main-content" @scroll="handleScroll()">
    <div class="shadow-card card genre-sub-navbar">
      <div class="row">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-start">
            <div class="top-menu-list me-2" id="genre_menu_list" role="tablist">
              <ul class="list-inline mt-4">
                <li
                  class="list-inline-item ms-2"
                  @click="scrollToElementWithMargin('genre_overview_v2', 80)"
                >
                  <a
                    data-section-main="#genre_overview_v2"
                    id="genre_overview_tab"
                    class="active"
                  >
                    <img src="/media/svg/br-gray.svg" alt="br-gray icon" />
                    <span class="social-icon text-xs-center"> Overview </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin(
                      'genre_listeners_benchmark_v2',
                      100
                    )
                  "
                >
                  <a
                    data-section-main="#genre_listeners_benchmark_v2"
                    id="genre_bench_mark_tab"
                  >
                    <img src="/media/svg/revs.svg" alt="br-blue icon" />
                    <span class="social-icon text-xs-center">
                      Listeners Benchmarks
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin('genre_social_media_v2', 80)
                  "
                >
                  <a
                    data-section-main="#genre_social_media_v2"
                    id="genre_social_media_tab"
                  >
                    <img src="/media/svg/social.svg" alt="br-blue icon" />
                    <span class="social-icon text-xs-center">
                      Social Media
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="scrollToElementWithMargin('genre_all_artists_v2', 80)"
                >
                  <a
                    data-section-main="#genre_all_artists_v2"
                    id="genre_all_artists_tab"
                  >
                    <img src="/media/svg/BR-Blue.svg" alt="br-blue icon" />
                    <span class="social-icon text-xs-center">
                      All Artists
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="search-bar">
              <input
                class="form-control form-control-solid me-3 search-field"
                placeholder="Search other artists"
              />
              <svg
                class="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row genre-detail-v2 mt-8" id="genre-detail-v2">
      <div id="genre_overview_v2">
        <div class="genre-content-heading">Overview</div>
        <GenreOverviewV2 :computed-values="computedValues" />
      </div>
      <div id="genre_listeners_benchmark_v2">
        <div class="genre-content-heading">Listener Benchmarks</div>
        <ListenerBenchmarksV2 />
      </div>
      <div id="genre_social_media_v2">
        <div class="genre-content-heading">Streams</div>
        <GenreSocialMediaV2 />
      </div>
      <div id="genre_all_artists_v2">
        <div class="genre-content-heading mb-2">
          All Artists
          {{
            route.query && route.query.type && route.query.type === "main"
              ? "(AI Catalogues)"
              : " "
          }}
        </div>
        <AllArtistsV2 />
      </div>
    </div>
  </div>
</template>
<script>
import GenreOverviewV2 from "@/genre/GenreOverviewV2";
import GenreSocialMediaV2 from "@/genre/GenreSocialMediaV2";
import ListenerBenchmarksV2 from "@/genre/ListenerBenchmarksV2";
import AllArtistsV2 from "@/genre/AllArtistsV2";
import { onBeforeMount, ref } from "vue";
import { scrollToElementWithMargin } from "@/utils/staticHelper";
import $ from "jquery";
import { useRoute } from "vue-router";

export default {
  name: "GenreDetailContentV2",
  components: {
    GenreOverviewV2,
    GenreSocialMediaV2,
    ListenerBenchmarksV2,
    AllArtistsV2,
  },
  props: {
    computedValues: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const activeTab = ref("overview_v2");
    const route = useRoute();
    onBeforeMount(() => {
      window.addEventListener("scroll", handleScroll);
    });
    const handleScroll = () => {
      const scrollPos = $(document).scrollTop() + 190;
      $("#genre_menu_list a").each(function () {
        const currLink = $(this);
        const refElement = $(currLink.data("section-main"));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $("#genre_menu_list li a").removeClass("active");
          changeTab(currLink.data("section-main"));
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
      if (scrollPos === 190 || scrollPos < 190) {
        $("#genre_overview_tab").addClass("active");
        changeTab("#genre_overview_v2");
      }
    };
    const changeTab = (tabName) => {
      activeTab.value = tabName;
    };
    return {
      activeTab,
      scrollToElementWithMargin,
      handleScroll,
      changeTab,
      route,
    };
  },
};
</script>
<style lang="scss" scoped>
.genre-sub-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  transition: 0.8s;
}

.card {
  .list-inline {
    .list-inline-item {
      padding: 10px;
      cursor: pointer;

      .social-icon {
        color: #33647f;
        font-size: 12px;
        font-weight: 700;
      }
    }

    .active {
      border-radius: 5px;
      background: #dee4ee;
      padding: 10px;
    }
  }
}

input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}

.search-bar {
  position: relative;
  width: -webkit-fill-available;
  margin-right: 1rem;
  margin-top: 12px;
}

.search-field {
  padding: 0.8rem 1.5rem !important;
  line-height: 0 !important;
}

.search-icon {
  position: absolute;
  right: 3%;
  font-size: 24px;
  bottom: 37%;
  fill: #33647f;
}

.genre-detail-v2 {
  --bs-gutter-x: 0 !important;

  .genre-content-heading {
    padding-left: 35px;
    font-size: 16px;
    color: #33647f;
    font-weight: 700;
  }
}
</style>
