<template>
  <SelectedGenreArtistsNew
    :genre="genre"
    :fontStyle="true"
    :margin="true"
    :show-genre-profile-button="false"
    v-if="route.query.type === 'main'"
  />
  <SelectedGenreArtists
    :genre="genre"
    :fontStyle="true"
    :margin="true"
    :show-genre-profile-button="false"
    v-else
  />
</template>
<script>
import { useRoute } from "vue-router";
import SelectedGenreArtists from "@/prefilledGenres/components/SelectedGenreArtists";
import SelectedGenreArtistsNew from "@/prefilledGenres/components/SelectedGenreArtistsNew";

export default {
  name: "AllArtistsV2",
  data() {
    return {
      loading: false,
      genre: null,
      route: useRoute(),
    };
  },
  components: { SelectedGenreArtists, SelectedGenreArtistsNew },
  mounted() {
    if (this.route.query && this.route.query.genre) {
      this.getArtistsByGenre(this.route.query.genre);
    }
  },
  methods: {
    getArtistsByGenre(genre) {
      this.genre = genre;
    },
  },
};
</script>
