<template>
  <div class="left-sidebar-v2 me-10">
    <div class="shadow-card card left-sidebar-card-v2" id="genre_card">
      <div class="card-body">
        <div class="card-head d-flex flex-column">
          <span
            class="heading-sideBar truncate"
            data-tag="span"
            title=""
            data-tooltip="true"
            :data-text="genreName ? genreName.toUpperCase() : 'Genre Name'"
          >
            {{ genreName ? genreName.toUpperCase() : "" }}</span
          >
          <hr />
        </div>
        <div class="card-button">
          <button class="btn btn-sm btn-primary btn-style me-3">Listen</button>
          <button class="btn btn-sm btn-primary btn-style me-3">+CRM</button>
          <ArtistMenuComponent
            :artist="{}"
            :from-drawer="true"
            :buttons="[
              'discover',
              'watchlist',
              'crm',
              'saveArtist',
              'Follow',
              'aiSandbox',
            ]"
          />
        </div>
        <div class="quick-facts ms-3 mb-8 mt-8">
          <div class="sub-headings-sidebar mb-2">
            <a href="#">
              <img alt="Logo" src="/favicon.ico" class="h-20px" />
            </a>
            <span> Quick Facts</span>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <p class="facts-figures">Min Revenues:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Median Revenues:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Max. Revenues:</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="'$' + getRevenue('minimumRevenue')"
                >
                  $ {{ getRevenue("minimumRevenue") }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="'$' + getRevenue('medianRevenue')"
                >
                  $ {{ getRevenue("medianRevenue") }}</span
                >
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="'$' + getRevenue('maximumRevenue')"
                >
                  $ {{ getRevenue("maximumRevenue") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="genre-v2 ms-3 mt-8 mb-8">
          <p class="sub-headings-sidebar">Related - Genre</p>
          <span class="badge badge-secondary m-1"> Drum n Bass </span>
          <span class="badge badge-secondary m-1 active"> Jungle </span>
          <span class="badge badge-secondary m-1"> Breaks </span>
          <span class="badge badge-secondary m-1"> Liquid </span>
          <span class="badge badge-secondary m-1"> TechStep </span>
        </div>
        <hr />
        <div class="top-10-artists ms-3 mt-8 mb-8">
          <p class="sub-headings-sidebar">
            Top 10 Artists - 36M Listeners Growth
          </p>
          <div class="list-group-flush">
            <div
              class="list-group-item mb-2"
              v-for="(
                artist, index
              ) in genreDetail.top_10_artists_36_month_listeners_growth"
              :key="index"
            >
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="routeToArtistDetailPageV2(artist)"
              >
                <el-avatar
                  :size="30"
                  :src="
                    artist.avatar ? artist.avatar : '/media/avatars/blank.png'
                  "
                />
                <span
                  class="ms-2 listed-artist-name truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="artist.name ? artist.name : ''"
                  >{{ artist.name ? artist.name : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="top-10-artists ms-3 mt-8 mb-8">
          <p class="sub-headings-sidebar">Top 10 Artists - by Revenues</p>
          <div class="list-group-flush">
            <div
              class="list-group-item mb-2"
              v-for="(artist, index) in genreDetail.top_10_artists_by_revenue"
              :key="index"
            >
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="routeToArtistDetailPageV2(artist)"
              >
                <el-avatar
                  :size="30"
                  :src="
                    artist.avatar ? artist.avatar : '/media/avatars/blank.png'
                  "
                />
                <span
                  class="ms-2 listed-artist-name truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="artist.name ? artist.name : ''"
                  >{{ artist.name ? artist.name : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="top-10-artists ms-3 mt-8 mb-8">
          <p class="sub-headings-sidebar">Top 10 Artists - by Fanbase</p>
          <div class="list-group-flush">
            <div
              class="list-group-item mb-2"
              v-for="(artist, index) in genreDetail.top_10_artists_by_fanbase"
              :key="index"
            >
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="routeToArtistDetailPageV2(artist)"
              >
                <el-avatar
                  :size="30"
                  :src="
                    artist.avatar ? artist.avatar : '/media/avatars/blank.png'
                  "
                />
                <span
                  class="ms-2 listed-artist-name truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="artist.name ? artist.name : ''"
                  >{{ artist.name ? artist.name : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtistMenuComponent from "@/artists/components/ArtistMenuComponent";
import { useStore } from "vuex";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "GenreSideBarV2",
  components: {
    ArtistMenuComponent,
  },
  props: {
    genreDetail: {
      type: Object,
      required: true,
    },
    genreName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const getRevenue = (key) => {
      if (
        props.genreDetail &&
        props.genreDetail.computedValues &&
        props.genreDetail.computedValues.computedProperties &&
        props.genreDetail.computedValues.computedProperties[key]
      ) {
        return formatNumberIntoHumanizeForm(
          props.genreDetail.computedValues.computedProperties[key]
        );
      } else return formatNumberIntoHumanizeForm(0);
    };
    const routeToArtistDetailPageV2 = async (artist) => {
      await store.dispatch("ArtistModule/setArtistUUID", artist.uuid);
      window.open(`/ai-artist/${artist.spotifyID}`, "_blank");
    };
    return { getRevenue, routeToArtistDetailPageV2 };
  },
};
</script>
<style lang="scss" scoped>
.listed-artist-name {
  color: #3f4254;
  font-weight: 500;
  font-size: 13px;
}

.list-group-item {
  border: none !important;
}
</style>
