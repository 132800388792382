<template>
  <div
    class="genre-detail-v2 mb-8"
    id="genre_detail_v2"
    v-loading.fullscreen.lock="loading"
  >
    <GenreSideBarV2
      :genre-detail="genreDetail"
      :genre-name="route.query.genre"
    />
    <GenreDetailContentV2
      :computed-values="
        genreDetail &&
        genreDetail.computedValues &&
        genreDetail.computedValues.computedProperties
          ? genreDetail.computedValues.computedProperties
          : {}
      "
    />
  </div>
</template>
<script>
import GenreSideBarV2 from "@/genre/GenreSideBarV2";
import GenreDetailContentV2 from "@/genre/GenreDetailContentV2";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getGenreDetailFromMongoV2 } from "@/api/genreResult";
export default {
  name: "GenreDetailV2",
  components: {
    GenreSideBarV2,
    GenreDetailContentV2,
  },
  setup() {
    const loading = ref(false);
    const genreDetail = ref({});
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        if (route.query && route.query.genre) {
          let url = "";
          if (route.query.type === "main") {
            url = "/mongo-chartmetric-genres";
          } else {
            url = "/mongo-genre";
          }
          const { data } = await getGenreDetailFromMongoV2(
            url,
            route.query.genre
          );
          genreDetail.value = data;
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });

    return { loading, genreDetail, route };
  },
};
</script>
