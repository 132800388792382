<template>
  <div class="rounded-bar-chart-v2">
    <apexchart
      :series="series"
      :options="options"
      type="bar"
      :height="barHeight"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "RoundedBarGraph",
  props: {
    barColor: {
      type: String,
      default: "#91D6FC",
    },
    showYAxis: {
      type: Boolean,
      default: true,
    },
    showXAxis: {
      type: Boolean,
      default: true,
    },
    barHeight: {
      type: String,
      default: "300",
    },
    barWidth: {
      type: String,
      default: "85%",
    },
    graphSeries: {
      type: Array,
      required: true,
    },
    seriesName: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
        grid: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: props.barWidth,
          startingShape: "flat",
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories:
          props.graphSeries.length > 0
            ? [
                "10%",
                "20%",
                "30%",
                "40%",
                "50%",
                "60%",
                "70%",
                "80%",
                "90%",
                ">100%",
              ]
            : 0,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: props.showXAxis,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        show: props.showYAxis,
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
        },
        marker: {
          show: false,
        },
      },
      colors: [props.barColor],
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    });
    const series = ref([
      {
        data: props.graphSeries ? props.graphSeries : [],
        name: props.seriesName,
      },
    ]);

    return { options, series };
  },
};
</script>
<style lang="scss">
.rounded-bar-chart-v2 {
  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(0% 0% -100% 0% round 12px);
  }
}
</style>
