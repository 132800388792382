<template>
  <div class="listener-benchmarks-v2 mt-2">
    <el-row :gutter="12">
      <el-col :lg="12" :md="12" :sm="24" class="margin-bottom-12">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Listeners vs Followers"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
          v-if="followers.length > 0 && !loading"
        >
          <template v-slot:body>
            <ScatterChartV2
              x-axis-title="Followers"
              y-axis-title="Listeners"
              series-name="Followers"
              :graph-data="followers"
            />
          </template>
        </CardToolbar>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24" class="margin-bottom-12">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Listeners vs Fanbase"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
          v-if="!loading && fanbase.length > 0"
        >
          <template v-slot:body>
            <ScatterChartV2
              x-axis-title="Fanbase"
              y-axis-title="Listeners"
              series-name="Fanbase"
              :graph-data="fanbase"
            />
          </template>
        </CardToolbar>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Listeners vs Total Playlists"
          :fontStyle="true"
          :margin="true"
          :padding="false"
          :shadow-class="true"
          v-if="!loading && totalPlaylists.length > 0"
        >
          <template v-slot:body>
            <ScatterChartV2
              x-axis-title="Total Playlists"
              y-axis-title="Listeners"
              series-name="Total Playlists"
              :graph-data="totalPlaylists"
            />
          </template>
        </CardToolbar>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24">
        <EmptyCardLoading :loading="loading" v-if="loading" />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Listeners vs Editorial"
          :fontStyle="true"
          :margin="true"
          :padding="false"
          :shadow-class="true"
          v-if="!loading && editorialGrowth.length > 0"
        >
          <template v-slot:body>
            <ScatterChartV2
              x-axis-title="Editorial"
              y-axis-title="Listeners"
              series-name="Editorial"
              :graph-data="editorialGrowth"
            />
          </template>
        </CardToolbar>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import CardToolbar from "@/common/components/CardToolbar";
import ScatterChartV2 from "@/common/components/ScatterChartV2";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getGenreListenerBenchmarksV2 } from "@/api/genreResult";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";

export default {
  name: "ListenerBenchmarksV2",
  components: { ScatterChartV2, CardToolbar, EmptyCardLoading },
  setup() {
    const loading = ref(false);
    const followers = ref([]);
    const fanbase = ref([]);
    const totalPlaylists = ref([]);
    const editorialGrowth = ref([]);
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        if (route.query && route.query.genre) {
          let url = "";
          if (route.query.type === "main") {
            url = "/mongo-chartmetric-genres";
          } else {
            url = "/mongo-genre";
          }
          let { data } = await getGenreListenerBenchmarksV2(
            url,
            route.query.genre
          );
          followers.value = data.followers;
          fanbase.value = data.fanbase;
          totalPlaylists.value = data.total_playlists;
          editorialGrowth.value = data.editorial;
          data = {};
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });

    return { loading, followers, fanbase, totalPlaylists, editorialGrowth };
  },
};
</script>
